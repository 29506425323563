<template>
    <vx-card class="common-card-header" :title="title" :subtitle="`Total: ${registration.length}`">
        <template slot="actions">
            <div class="flex xl:flex-row md:flex-nowrap flex-wrap gap-y-3">
                <flat-pickr v-model="purchaseDate" :config="dateTimeConfig" @on-change="handleFilter" class="w-1/3 mr-5" placeholder="Purchase Date" />
                <vs-select name="status" v-model="selectedStatus" @change="handleFilter" class="w-1/3 mr-5">
                    <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in selectStatus" />
                </vs-select>
                <vs-input v-model="searchString" class="m-0" @keyup="handleFilter" placeholder="Search"></vs-input>
                <vs-button class="ml-2" @click="clearFilter">Clear</vs-button>
            </div>
        </template>
        <vs-table stripe :data="registration" v-if="registration.length" :sst="true" @sort="handleSort">
            <template #thead>
                <vs-th sort-key="DisplayName">Name</vs-th>
                <vs-th sort-key="MobileNumber">Phone No</vs-th>
                <vs-th sort-key="PassNumber">Pass Number</vs-th>
                <vs-th sort-key="ProductName">Pass Name</vs-th>
                <vs-th class="width1"></vs-th>
                <vs-th class="width1" sort-key="RegistrationPlate">Registration</vs-th>
                <vs-th class="" sort-key="TransactionDate">Purchase Date</vs-th>
                <vs-th class="width1" sort-key="Status">Status</vs-th>
                <vs-th class="width1"></vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr v-for="(tr, index) in registration" :key="index" :data="tr">
                    <vs-td>{{tr.displayName}}</vs-td>
                    <vs-td>{{tr.mobileNumber}}</vs-td>
                    <vs-td>{{tr.passNumber}}</vs-td>
                    <vs-td>{{tr.productName}}</vs-td>
                    <vs-td>
                        <vs-avatar class="cursor-default" :color="tr.statusColor ? tr.statusColor : '#ccc'" size="large" icon-pack="feather" icon="icon-credit-card" />
                    </vs-td>
                    <vs-td>
                        {{tr.registrationPlate}}
                    </vs-td>
                    <vs-td>{{tr.transactionDate | formatLongDate}}</vs-td>
                    <vs-td>
                        <vs-chip color="success">{{tr.status}}</vs-chip>
                    </vs-td>
                    <vs-td class="tbl-last-child">
                        <vs-dropdown class="desktop-action cursor-pointer">
                            <vs-button class="btn-drop" type="filled" icon="expand_more" icon-after>Action</vs-button>
                            <vs-dropdown-menu>
                                <vs-dropdown-item @click="editSelected(tr)"> View Details </vs-dropdown-item>
                                <vs-dropdown-item @click="downloadSelected(tr)"> Print </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                        <div class="mobile-action">
                            <vs-button class="mobile-btn whitespace-nowrap" size="small" color="primary" @click="editSelected(tr)" icon-pack="feather" icon="icon-eye"></vs-button>
                            <vs-button class="mobile-btn whitespace-nowrap" size="small" color="dark" @click="downloadSelected(tr)" icon-pack="feather" icon="icon-printer"></vs-button>
                        </div>
                        <!-- <vs-button color="danger">Action</vs-button> -->
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <NoData v-else class="mt-base" :headTitle="noData.title" :description="noData.description"></NoData>
        <checkRegistration v-if="openAddWindow" :selectedReg="selectedReg" :regType="title" :openAddWindow="openAddWindow" @loadVehicles="loadVehicles" @onClose="handleClose"></checkRegistration>
        <ViewFile :isOpenTerms="isOpenTerms" :URL="fileurl" @closeWindow="closeWindow"></ViewFile>
    </vx-card>
    </template>
    
    <script>
    import moment from "moment";
    import flatPickr from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import {
        carData
    } from "../../store/api/car";
    import checkRegistration from './checkRegistration';
    import _ from 'lodash';
    
    export default {
        data() {
            return {
                selectedReg: undefined,
                openAddWindow: false,
                registration: [],
                intervalId: null,
                title: "All Season Passes",
                searchString: "",
                purchaseDate: undefined,
                selectedStatus: "Active",
                selectStatus: [
                    "Active",
                    "Pending",
                    "Processing",
                    "Presale",
                    "Cancelled",
                    "Expired",
                    "Blacklisted"
                ],
                noData: {
                    title: "No Records Found"
                },
                isOpenTerms: false,
                fileurl: "",
                activePage: 1,
                dateTimeConfig: {
                    wrap: true,
                    altFormat: "d-m-Y",
                    altInput: true,
                    allowInput: true,
                    dateFormat: "Y-m-d",
                    enableTime: false,
                    time_24hr: false,
                    defaultDate: null
                },
                sortKey: "DisplayName",
                sortStatus: "asc",
            };
        },
        mounted() {
            this.scroll()
        },
        components: {
            checkRegistration,
            flatPickr
        },
        async created() {
            await this.loadVehicles();
        },
        methods: {
            scroll() {
                window.onscroll = () => {
                    if (Math.ceil(document.documentElement.scrollTop) + document.documentElement.clientHeight === document.documentElement.scrollHeight) {
                        console.log("data checking", Math.ceil(document.documentElement.scrollTop) + document.documentElement.clientHeight, "=", document.documentElement.scrollHeight)
                        if (this.purchaseDate === undefined || this.purchaseDate === null || this.purchaseDate === '') {
                            this.activePage += 1;
                            //this.loadVehicles();
                            this.handleSort(this.sortKey, this.sortStatus)
                        }
                    }
                };
            },
            async clearFilter() {
                this.purchaseDate = null;
                this.activePage = 1;
                this.selectedStatus = "Active";
                await this.loadVehicles();
            },
            async loadVehicles() {
                let data = data = {
                    'Status': this.selectedStatus,
                    'Search': this.searchString,
                    'PageNumber': this.activePage,
                    'SortKey': this.sortKey,
                    'SortBy': this.sortStatus,
                    'PassType': 'DayPass'
                }
                const registrationList = await carData.SearchActiveCarRegistrationByKey(data);
                //const registrationList = await carData.SearchActiveCarRegistration(this.activePage, this.selectedStatus, this.searchString);
    
                if (this.activePage === 1) {
                    this.registration = [];
                }
    
                registrationList.forEach(element => {
                    this.registration.push(element);
                });
            },
            async loadVehiclesByDate() {
                const registrationList = await carData.SearchActiveCarRegistrationByPurchaseDate(this.selectedStatus, this.purchaseDate);
    
                if (this.activePage === 1) {
                    this.registration = [];
                }
    
                registrationList.forEach(element => {
                    this.registration.push(element);
                });
            },
            handleClose() {
                if (this.openAddWindow) {
                    this.openAddWindow = !this.openAddWindow;
                    this.loadVehicles();
                }
            },
            async handleFilter() {
                //this.purchaseDate = this.purchaseDate.trim();
                if (this.purchaseDate === undefined || this.purchaseDate === null || this.purchaseDate === '') {
                    this.activePage = 1;
                    this.registration = [];
                    if (this.intervalId) {
                        clearTimeout(this.intervalId)
                    }
                    this.intervalId = setTimeout(async () => {
                        await this.loadVehicles();
                    }, 800);
                } else {
                    this.activePage = 1;
                    this.registration = [];
                    if (this.intervalId) {
                        clearTimeout(this.intervalId)
                    }
                    this.intervalId = setTimeout(async () => {
                        //await this.loadVehiclesByDate();
                        await this.handleSort(this.sortKey, this.sortStatus)
                    }, 800);
    
                }
            },
            editSelected(tr) {
                this.selectedReg = tr;
                if (!this.openAddWindow) {
                    this.openAddWindow = !this.openAddWindow;
                }
            },
            async downloadSelected(tr) {
                var data = await carData.downloadSeasonPass(tr.id);
                if (data) {
                    this.isOpenTerms = true;
                    this.fileurl = data.url;
                }
            },
            closeWindow() {
                this.isOpenTerms = false;
            },
            async handleSort(key, active) {
                // // let _print = `the user ordered: ${key} ${active}\n`
                // // if(key === "TransactionDate"){
                // //     this.registration = _.orderBy(this.registration, function(o) { return new moment(o.transactionDate); }, [active]);
                // //     return;
                // // }
                //this.registration = _.orderBy(this.registration, key, active);
    
                this.sortKey = key;
                this.sortStatus = active;
    
                let data = data = {
                    'Status': this.selectedStatus,
                    'Search': this.searchString,
                    'PageNumber': this.activePage,
                    'SortKey': key,
                    'SortBy': active,
                    'PurchaseDate': this.purchaseDate
                }
    
                const registrationList = await carData.SearchActiveCarRegistrationByKey(data);
                if (this.activePage === 1) {
                    this.registration = registrationList;
                } else {
                    registrationList.forEach(element => {
                        this.registration.push(element);
                    });
                }
            }
        }
    };
    </script>
    
    <style lang="scss">
    @media (min-width: 576px) {
        .mobile-action {
            display: none;
        }
    }
    
    @media (max-width:1199px) {
        .vx-card {
            &.common-card-header {
                >.vx-card__header {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }
    }
    
    @media (max-width: 575px) {
        .desktop-action {
            display: none
        }
    
        .tbl-last-child {
            .mobile-action {
                display: flex;
                column-gap: 15px;
                align-items: center;
    
                button {
                    width: 32px !important;
                    height: 32px !important;
                }
            }
        }
    
    }
    </style>
    